import createLogger from "debug";
import { isDebugEnabled, DEBUG_KEY } from "@leon-hub/debug";
import { normalizeError } from "@leon-hub/errors";
import { assert, isObject, isUndefined, isString, isFunction } from "@leon-hub/guards";
import { getUuid } from "@leon-hub/utils";
function isRegularPostMessageData(value) {
  return isObject(value) && isString(value.eventName) && isString(value.clientId) && isString(value.initiator) && (isUndefined(value.isSendWithPostMessageBus) || value.isSendWithPostMessageBus === true);
}
function isCasAuthPostMessageData(value) {
  return isObject(value) && isString(value.id) && isString(value.type) && value.isSendWithPostMessageBus === true;
}
function isPostMessageData(value) {
  return isRegularPostMessageData(value) || isCasAuthPostMessageData(value);
}
function isWindowProxy(window2) {
  return isFunction(window2.postMessage);
}
const currentClientId = /* @__PURE__ */ getUuid();
function makeMessage(event, payload, from) {
  return {
    isSendWithPostMessageBus: true,
    eventName: event,
    initiator: from,
    clientId: currentClientId,
    payload
  };
}
class PostMessageBus {
  constructor(options) {
    this.logger = createLogger("post-message-bus");
    this.isParent = window.parent === window;
    this.events = {};
    this.skipSourceCheck = false;
    this.debug = isDebugEnabled(DEBUG_KEY.POST_MESSAGE);
    assert(options.initiator.length > 0, "options.initiator should be informative about component name");
    const parentOptions = this.isParent ? {
      target: window.parent,
      targetOrigin: "*"
    } : {};
    this.options = {
      ...parentOptions,
      ...options
    };
    this.allowMessagesFromDomain = options.allowMessagesFromDomain || null;
    this.skipSourceCheck = options.skipSourceCheck ?? false;
    this.targetOrigin = this.options.targetOrigin;
    assert(isObject(this.options.target), "Target must be an instance of Window");
    this.target = this.options.target;
    this.boundEventCallback = this.handleMessage.bind(this);
    window.addEventListener("message", this.boundEventCallback, false);
    this.log(`new post message bus created by ${this.options.initiator} initiator`);
  }
  get logPrefix() {
    return `[${this.isParent ? "parent" : "child"} in target origin: ${this.targetOrigin}]`;
  }
  dispose() {
    window.removeEventListener("message", this.boundEventCallback);
    this.log(`post message bus for ${this.options.initiator} initiator disposed`);
  }
  on(event, callback) {
    const eventName = event.toString();
    this.originEvents[eventName] = this.originEvents[eventName] || [];
    this.originEvents[eventName].push(callback);
    this.log(`
       starting event listener
       for event "${eventName}"
       at ${new Date(Date.now()).toISOString()}`);
  }
  get originEvents() {
    if (!this.events[this.targetOrigin]) {
      this.events[this.targetOrigin] = {};
    }
    return this.events[this.targetOrigin];
  }
  emit(event, payload) {
    if (isWindowProxy(this.target)) {
      try {
        if (this.isParent) {
          this.log(`
             emit event "${event.toString()}"
             with payload: ${payload ? JSON.stringify(payload) : "none"}
             at ${new Date(Date.now()).toISOString()}`);
          this.target.postMessage(makeMessage(event.toString(), payload, this.options.initiator), this.targetOrigin);
          return true;
        }
        this.log(`
           emit event "${event.toString()}"
           with payload: ${payload ? JSON.stringify(payload) : "none"}
           at ${new Date(Date.now()).toISOString()}`);
        this.target.postMessage(makeMessage(event.toString(), payload, this.options.initiator), "*");
        return true;
      } catch (rawError) {
        const error = normalizeError(rawError);
        const reassignedError = new Error(
          `Error while emitting postMessage. eventName: ${event.toString()} ${error.message}`
        );
        reassignedError.stack = error.stack;
        console.error(reassignedError);
      }
    }
    return false;
  }
  checkEvent(event) {
    const { data } = event;
    const { isSendWithPostMessageBus } = data;
    if (!isSendWithPostMessageBus) {
      return false;
    }
    if (this.allowMessagesFromDomain !== null) {
      return new URL(event.origin).hostname === new URL(this.allowMessagesFromDomain).hostname;
    }
    return this.checkEventOrigin(event.origin, event.source);
  }
  checkEventOrigin(eventOrigin, eventSource) {
    if (this.skipSourceCheck && (eventOrigin === "null" || eventOrigin === "file://" || window.location.protocol === "file:")) {
      return true;
    }
    if (this.skipSourceCheck) {
      return true;
    }
    if (this.isParent) {
      if (this.targetOrigin === "*") {
        return true;
      }
      return new URL(eventOrigin).hostname === new URL(this.targetOrigin).hostname && eventSource === this.target;
    }
    return eventSource === this.target;
  }
  off(event, callback) {
    const listeners = this.originEvents[event.toString()];
    if (!listeners)
      return false;
    for (let index = 0; index < listeners.length; index += 1) {
      if (listeners[index] === callback) {
        listeners.splice(index, 1);
        return true;
      }
    }
    return false;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  handleMessage(event) {
    assert(event instanceof MessageEvent);
    if (isPostMessageData(event.data)) {
      const eventName = isRegularPostMessageData(event.data) ? event.data.eventName : event.data.type;
      if (this.checkEvent(event)) {
        const { payload } = event.data;
        const originEvent = this.originEvents[eventName];
        if (isUndefined(originEvent)) {
          return;
        }
        if (isRegularPostMessageData(event.data)) {
          const { clientId } = event.data;
          const sameContext = clientId === currentClientId;
          if (!this.options.isP2P && sameContext) {
            return;
          }
        }
        for (let index = 0; index < originEvent.length; index += 1) {
          const originEventCallback = this.originEvents[eventName][index];
          try {
            originEventCallback(payload);
          } catch (rawError) {
            const error = normalizeError(rawError);
            const reassignedError = new Error(`Error happened inside originEventCallback. ${error.message}`);
            reassignedError.stack = error.stack;
            console.warn(reassignedError);
          }
        }
      }
    }
  }
  log(...messages) {
    if (this.debug) {
      this.logger.log(this.logPrefix, ...messages);
    }
  }
}
class PostMessageEvent {
  constructor(key) {
    this.key = key;
  }
  toString() {
    return this.key;
  }
}
const _CasAuthPostMessageEvent = class _CasAuthPostMessageEvent extends PostMessageEvent {
};
_CasAuthPostMessageEvent.checkCookie = /* @__PURE__ */ new PostMessageEvent("checkCookie");
_CasAuthPostMessageEvent.reloadPage = /* @__PURE__ */ new PostMessageEvent("reloadPage");
_CasAuthPostMessageEvent.cookieIsOk = /* @__PURE__ */ new PostMessageEvent("cookieIsOk");
let CasAuthPostMessageEvent = _CasAuthPostMessageEvent;
export {
  CasAuthPostMessageEvent,
  PostMessageBus,
  PostMessageEvent
};
