import { defineStore } from 'pinia';
import { ref } from 'vue';

const useAppFullscreenStore = defineStore('app-fullscreen', () => {

  const isAppInFullscreenMode = ref(
    process.env.VUE_APP_RENDERING_CSR && !!document.fullscreenElement,
  );

  function setFullscreenMode(value: boolean): void {
    isAppInFullscreenMode.value = value;

    if (process.env.VUE_APP_RENDERING_CSR) {
      if (value && !document.fullscreenElement) {
        void document.documentElement.requestFullscreen();
      } else if (!value && document.fullscreenElement) {
        void document.exitFullscreen();
      }
    }
  }

  if (process.env.VUE_APP_RENDERING_CSR) {
    document.addEventListener('fullscreenchange', () => {
      setFullscreenMode(!!document.fullscreenElement);
    });
  }

  return {
    isAppInFullscreenMode,
    setFullscreenMode,
  };
});

export default useAppFullscreenStore;
