import { safeCall, Deferred, promiseTimeout } from "@leon-hub/utils";
import { assert } from "@leon-hub/guards";
import debug from "debug";
const DEFAULT_INTERVAL = 200;
const DEFAULT_TIMEOUT = Infinity;
function mergeAbortSignals(...signals) {
  assert(signals.length > 0, "At least one signal is required");
  const controller = new AbortController();
  function onAbort() {
    controller.abort();
  }
  for (const signal of signals) {
    signal.addEventListener("abort", onAbort);
  }
  return controller.signal;
}
function normalizeSignal(signal) {
  return signal ?? new AbortController().signal;
}
function setupOptions({
  interval = DEFAULT_INTERVAL,
  timeout = DEFAULT_TIMEOUT,
  eager = false,
  idlers = [],
  signal
} = {}) {
  assert(timeout >= interval, "timeout must be greater than interval");
  const isTimeoutDefined = timeout > 0 && Number.isFinite(timeout);
  return {
    interval,
    timeout,
    eager,
    idlers,
    signal: isTimeoutDefined ? mergeAbortSignals(
      normalizeSignal(signal),
      createAbortSignalTimeout(timeout)
    ) : normalizeSignal(signal)
  };
}
function createAbortSignalTimeout(timeout) {
  const controller = new AbortController();
  setTimeout(() => {
    controller.abort();
  }, timeout);
  return controller.signal;
}
const supportsPerformanceAPI = () => typeof window !== "undefined" && "performance" in window && "getEntriesByType" in window.performance;
function isTrackableResourceType(type) {
  return type === "link" || type === "script" || type === "img";
}
function isTrackableResource(resource) {
  return "initiatorType" in resource && typeof resource.initiatorType === "string" && isTrackableResourceType(resource.initiatorType);
}
function isAnyResourceLoading() {
  if (!supportsPerformanceAPI()) return false;
  const resources = window.performance.getEntriesByType("resource");
  if (!resources.length) return true;
  return resources.some((resource) => resource.duration === 0 && isTrackableResource(resource));
}
function watchFetch({
  onBefore,
  onAfter
} = {
  onBefore: () => void 0,
  onAfter: () => void 0
}) {
  if (process.env.VUE_APP_RENDERING_SSR) return () => {
  };
  let isWatched = true;
  const prevFetch = window.fetch;
  window.fetch = async function wrappedFetch(...rest) {
    if (isWatched) safeCall(onBefore);
    try {
      return await Reflect.apply(prevFetch, this, rest);
    } finally {
      if (isWatched) safeCall(onAfter);
    }
  };
  return () => {
    isWatched = false;
    window.fetch = prevFetch;
  };
}
const whenClientNetworkIdle = async (options = {}) => {
  const {
    timeout,
    interval,
    eager
  } = setupOptions(options);
  assert(timeout >= interval, "timeout must be greater than interval");
  const startTime = Date.now();
  const timeoutTime = startTime + timeout;
  const d = new Deferred();
  let timeoutID;
  let lastConnectionChange = 0;
  function isIdleEnough(now) {
    return lastConnectionChange > 0 && now - lastConnectionChange > interval;
  }
  function update() {
    lastConnectionChange = Date.now();
  }
  const unwatchFetch = watchFetch({
    onBefore: update,
    onAfter: update
  });
  function tearDown(result) {
    unwatchFetch();
    clearTimeout(timeoutID);
    d.resolve(result);
  }
  function getRemainingTime(now) {
    return Math.max(0, now - startTime);
  }
  function runIdleCheck() {
    const now = Date.now();
    try {
      if (timeoutTime > now) {
        tearDown(false);
      } else if (isIdleEnough(now) && !isAnyResourceLoading()) {
        tearDown(true);
      } else if (eager) {
        tearDown(false);
      } else {
        timeoutID = setTimeout(runIdleCheck, interval);
      }
    } catch (err) {
      console.error(err);
      tearDown(false);
    }
  }
  void Promise.resolve().then(runIdleCheck);
  return d.promise.catch((reason) => {
    console.error(reason);
    return false;
  }).then((result) => result ? 0 : getRemainingTime(Date.now()));
};
const getTrackableEvents = () => /* @__PURE__ */ new Set([
  "resize",
  "orientationchange",
  "keydown",
  "keyup",
  "scroll",
  "pointermove",
  "pointerdown",
  "pointerup",
  "visibilitychange"
]);
function removeActivityListeners(trackableEvents, listener, listenerOptions) {
  for (const event of trackableEvents) {
    window.removeEventListener(event, listener, listenerOptions);
  }
}
function addActivityListeners(trackableEvents, listener, listenerOptions) {
  for (const event of trackableEvents) {
    window.addEventListener(event, listener, listenerOptions);
  }
}
const whenClientActivityIdle = async (options = {}) => {
  const {
    interval,
    eager,
    signal
  } = setupOptions(options);
  const d = new Deferred();
  const startTime = Date.now();
  const trackableEvents = getTrackableEvents();
  const listenerOptions = { capture: true, passive: true, signal };
  let timeoutID;
  let lastActivityTime = startTime;
  function tearDown(result) {
    removeActivityListeners(trackableEvents, updateActivity, listenerOptions);
    clearInterval(timeoutID);
    d.resolve(result);
  }
  function isStableEnough(now) {
    return lastActivityTime > 0 && now - lastActivityTime > interval;
  }
  function updateActivity() {
    const now = Date.now();
    const timeSpent = startTime - now;
    if (eager) {
      tearDown(timeSpent >= interval);
    } else {
      lastActivityTime = now;
    }
  }
  function checkStable({ skipEager } = {}) {
    const now = Date.now();
    if (isStableEnough(now)) {
      tearDown(true);
    } else if (!skipEager && eager) {
      tearDown(false);
    } else {
      timeoutID = setTimeout(checkStable, interval);
    }
  }
  addActivityListeners(trackableEvents, updateActivity, listenerOptions);
  signal.addEventListener("abort", () => tearDown(false));
  queueMicrotask(() => checkStable({ skipEager: true }));
  return d.promise.catch((reason) => {
    console.error(reason);
    return false;
  }).then((result) => result ? 0 : Date.now() - lastActivityTime);
};
const log = /* @__PURE__ */ debug("@leon-hub/idle");
const whenMultipleIdle = async (options) => {
  const {
    idlers,
    timeout,
    interval
  } = setupOptions(options);
  assert(idlers.length > 1, "At least two idlers are required");
  const hasTimeout = timeout > 0 && Number.isFinite(timeout);
  const startTime = Date.now();
  const deferred = new Deferred();
  const state = idlers.map(() => false);
  const isListOfIdlersResolved = () => state.every(Boolean);
  const isTimeout = (now) => hasTimeout && now - startTime > timeout;
  const runIdlerWithEagerMode = async (idler) => {
    const index = idlers.indexOf(idler);
    const remains = await idler({ interval, timeout, eager: true });
    const isIdle = remains === 0;
    state[index] = isIdle;
    if (isIdle && isListOfIdlersResolved()) {
      deferred.resolve();
    }
    return remains;
  };
  const waitForIdlers = async () => {
    const remainsList = await Promise.all(idlers.map(async (idler) => {
      let remains = interval;
      while (!deferred.finished) {
        if (isTimeout(Date.now())) {
          break;
        }
        remains = await runIdlerWithEagerMode(idler);
      }
      return remains;
    }));
    return Math.max(...remainsList);
  };
  log(`Started waiting for ${idlers.length} idlers`);
  return promiseTimeout({
    timeout,
    promise: waitForIdlers(),
    onTimeout: deferred.resolve
  }).catch((reason) => {
    console.warn(reason);
    return interval;
  });
};
export {
  whenClientActivityIdle,
  whenClientNetworkIdle,
  whenMultipleIdle
};
