import { defineStore } from 'pinia';
import {
  computed,
  ref, toRef,
} from 'vue';

import type { ITheme } from '@leon-hub/api-sdk';
import {
  themeDark,
  Theme,
} from '@leon-hub/api-sdk';
import {
  changeNavbarColor,
  getAutoTheme,
} from '@leon-hub/cordova';
import { isTheme } from '@leon-hub/api-guards';
import { themeHeaderName } from '@leon-hub/api';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useInitAppConfigStore, useSiteConfigStore, useStorageManagerStore } from 'web/src/modules/core/store';
import setApiHeaders from 'web/src/modules/core/utils/setApiHeaders';

const allThemes = [
  Theme.DARK,
  Theme.LIGHT,
];

const useThemeStore = defineStore('theme', () => {
  const storageManager = useStorageManagerStore();
  const initAppConfigStore = useInitAppConfigStore();
  const analytics = useAnalytics();

  function getInitTheme(key: 'current' | 'first'): Theme {
    const { default: defaultTheme } = initAppConfigStore.themeConfig;
    const initTheme = initAppConfigStore.themeConfig[key];
    if (initTheme && isTheme(initTheme)) {
      return initTheme;
    }

    return defaultTheme;
  }
  const siteConfigStore = useSiteConfigStore();
  const currentTheme = ref<Theme>(getInitTheme('current'));
  const customTheme = ref<Theme>();
  const firstTheme = ref<Theme>(getInitTheme('first'));
  const isAuto = ref(false);
  const customStatusBarTheme = ref<ITheme>();

  const settingsBlock = toRef(siteConfigStore, 'settingsBlock');

  const theme = computed(() => customTheme.value ?? currentTheme.value);
  const isDark = computed(() => theme.value === Theme.DARK);
  const isLight = computed(() => theme.value === Theme.LIGHT);
  const isThemeFooterSwitcherEnabled = computed(() => !!settingsBlock.value?.themeFooterSwitcherEnabled);
  const hasCustomTheme = computed(() => !!customTheme.value);

  let systemTheme: Theme | undefined;

  async function getDefaultSystemTheme(): Promise<Theme> {
    if (!systemTheme) {
      if (process.env.VUE_APP_PLATFORM_CORDOVA) {
        systemTheme = await getAutoTheme();
      } else {
        systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.DARK : Theme.LIGHT;
      }
    }

    return systemTheme;
  }

  function setCustomStatusbarTheme(value?: ITheme): void {
    customStatusBarTheme.value = value;
  }

  function setCustomStatusbarColor(value = false): void {
    setCustomStatusbarTheme(value ? themeDark : undefined);
  }

  async function setTheme(value: Theme): Promise<void> {
    if (process.env.VUE_APP_STYLE_SLOTT) {
      // eslint-disable-next-line no-param-reassign
      value = Theme.LIGHT;
    }

    currentTheme.value = value;

    setThemeHeaderAndChangeNavbarColor();

    await storageManager.setTheme(value);
  }

  function setThemeHeaderAndChangeNavbarColor(): void {
    setApiHeaders({
      [themeHeaderName]: theme.value,
    });

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const isLightTheme = theme.value === Theme.LIGHT;
      const hex = isLightTheme ? process.env.VUE_APP_NAVBAR_COLOR_LIGHT : process.env.VUE_APP_NAVBAR_COLOR_DARK;

      if (hex) {
        changeNavbarColor(hex, isLightTheme);
      }
    }
  }

  async function setFirstTheme(value: Theme): Promise<void> {
    await storageManager.setFirstTheme(value);
    firstTheme.value = value;
  }

  async function setIsAutoTheme(value: boolean): Promise<void> {
    isAuto.value = value;
    await storageManager.setIsAutoTheme(value);
  }

  async function syncState(): Promise<void> {
    const [storedTheme, firstThemeResponse, isAutoResponse] = await Promise.all([
      storageManager.getTheme(),
      storageManager.getFirstTheme(),
      storageManager.getIsAutoTheme(),
    ]);

    let firstThemeValue;
    if (isAutoResponse) {
      const defaultSystemTheme = await getDefaultSystemTheme();

      await setIsAutoTheme(true);
      await setTheme(defaultSystemTheme);
      if (!firstThemeResponse) {
        firstThemeValue = defaultSystemTheme;
      }
    } else if (isTheme(storedTheme) && allThemes.includes(storedTheme)) {
      await setTheme(storedTheme);
      if (!firstThemeResponse) {
        firstThemeValue = storedTheme;
      }
    }

    if (firstThemeValue) {
      await setFirstTheme(firstThemeValue);

      const formattedTheme = firstThemeValue.toLowerCase();
      analytics.push(AnalyticsEvent.Z_AB_TEST_NEW_USERS, {
        ab_test_light_mode: {
          [formattedTheme]: {
            visit: formattedTheme,
          },
        },
      });
    }
  }

  async function recalculateTheme(): Promise<void> {
    systemTheme = undefined;
    return syncState();
  }

  function toggleTheme(): void {
    const newTheme = currentTheme.value === Theme.DARK ? Theme.LIGHT : Theme.DARK;

    analytics.push(AnalyticsEvent.CLICK_MAP, {
      clickCounter: {
        changeMode: newTheme === Theme.LIGHT ? 'light-dark' : 'dark-light',
      },
    });
    void setIsAutoTheme(false);
    void setTheme(newTheme);
  }

  async function changeTheme(value: Theme): Promise<void> {
    let newTheme = value;
    if (value === Theme.AUTO) {
      newTheme = await getDefaultSystemTheme();
      void setIsAutoTheme(true);
    } else {
      void setIsAutoTheme(false);
    }

    return setTheme(newTheme);
  }

  function setCustomTheme(value?: Theme): void {
    customTheme.value = value;
    setThemeHeaderAndChangeNavbarColor();
  }

  void syncState();

  return {
    theme,
    firstTheme,
    isAuto,
    isDark,
    isLight,
    customStatusBarTheme,
    hasCustomTheme,
    recalculateTheme,
    setCustomStatusbarTheme,
    setCustomStatusbarColor,
    toggleTheme,
    changeTheme,
    setCustomTheme,
    isThemeFooterSwitcherEnabled,
  };
});

export default useThemeStore;
